import React, { useEffect, useState } from "react";
import { Control, useController, useWatch } from "react-hook-form";

import { OptOutSearchAndAdd } from "./CustomerOptOutEmail";
import { useLanguage } from "LanguageProvider";
import { Flex, RHCheckBoxInput, RHSelectInput, SelectOption } from "Atoms";
import { useImperativeQuery } from "Hooks";
import { GET_PUBLIC_ID_AND_SHOP_NAME } from "GraphQLQueries";
import { OptOutEmail } from "Types";

type Props = {
    control: Control<OptOutSearchAndAdd>;
    isCampaignOptOut: boolean;
};

export const CampaignOptOut: React.FC<Props> = ({ control, isCampaignOptOut = false }) => {
    const [shopOptions, setShopOptions] = useState<SelectOption[]>([]);
    const { translate } = useLanguage();

    const {
        field: { onChange }
    } = useController({ name: "optOutTypePerShops", control });

    const noCampaignsForAllShops = useWatch({ control, name: "noCampaignsForAllShops" });
    /** Only have one object in the array */
    const campaignShops = useWatch({ control, name: "optOutTypePerShops.0.publicShopIds" });

    const getPublicIdAndShopName = useImperativeQuery(GET_PUBLIC_ID_AND_SHOP_NAME);

    const getAllPublicIdsWithNames = async () => {
        const { data } = await getPublicIdAndShopName();
        if (!!data?.getAllShops) {
            const allShops = data.getAllShops as { publicId: string; name: string }[];
            const _shopOptions: SelectOption[] = allShops
                .filter(shop => !!shop.publicId)
                .map(shop => ({ value: shop.publicId, label: shop.name }));
            setShopOptions(_shopOptions);
        }
    };

    useEffect(() => {
        if (isCampaignOptOut) {
            getAllPublicIdsWithNames();
        }
    }, [isCampaignOptOut]);

    useEffect(() => {
        if (noCampaignsForAllShops) {
            onChange([
                {
                    optOutFrom: OptOutEmail.CAMPAIGNS,
                    allShops: noCampaignsForAllShops,
                    publicShopIds: []
                }
            ]);
        }
    }, [noCampaignsForAllShops]);

    return (
        <Flex direction={"column"} width={"100%"}>
            <RHCheckBoxInput
                control={control}
                name="noCampaignsForAllShops"
                formLabel={translate("allCampaignRestaurants")}
                isDisabled={!isCampaignOptOut}
            />

            <RHSelectInput
                control={control}
                name={"optOutTypePerShops.0.publicShopIds"}
                formLabel={`${translate("restaurants")} (${translate("campaigns").toLowerCase()})`}
                options={shopOptions}
                isDisabled={noCampaignsForAllShops || !isCampaignOptOut}
                isClearable
                isMulti
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                key={campaignShops?.length}
            />
        </Flex>
    );
};
